// ignore-string-externalization

export {
  Box,
  Stack,
  ImageAspectBox,
  ImageAspectBox1x1,
  ImageAspectBox3x5,
  ImageAspectBox5x4,
  ImageAspectBox16x9,
  ImageAspectBox21x9,
  ImageAspectBox1x2,
} from './Box';

export { HeadlineWithSupertextComponent } from './HeadlineWithSupertext';
export { Headline } from './Headline';
export { Supertext, StyledSupertext } from './Supertext';
export { FeaturedContentHeadline } from './FeaturedContentHeadline';
export { useWidth } from './useWidth';
export const CUSTOM_IPAD_WIDTH = '834px';
